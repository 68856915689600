ngb-typeahead-window {
  &.search-location-popup {
    dropdown-item:not(.disabled).active {
      background-color: #FFFFFF35 !important;
      color: white;
    }

    .ngb-typeahead-item {
      display: flex;
      align-items: center;

      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }

      i {
        margin-right: 8px;
      }
    }
  }

  &.search-location-popup-mobile {
    background-color: rgba(#181C21, .9);
    color: white;
  }

  &.search-location-popup-desktop {
    color: white;
    border-bottom-left-radius: 19px;
    border-bottom-right-radius: 19px;
    margin-top: -5px !important;
    margin-right: -10px !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    box-shadow: none !important;
    min-height: 164px;
    padding-bottom: 1rem !important;
    background-color: #e7e7e7 !important;

    .dropdown-item {
      font-size: 1.05rem;
      color: black !important;

      &:not(.disabled).active {
        background-color: #FFFFFF !important;
        color: black !important;
      }
    }
  }

  y-position-right {
    transform: translate3d(438px, 46px, 0) !important;
  }

  y-position-left {
    transform: translate3d(76px, 46px, 0) !important;
  }
}
