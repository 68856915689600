.search-filters-mobile {
  .map-icon {
    margin-right: 0.755rem;
  }

  .search-filters-mobile-label {
    .custom-select:not([class*='px-']):not([class*='pr-']):not([multiple]):not([size]) {
      padding-right: 0 !important;
    }

    .custom-select {
      height: 1.9rem;
      line-height: 1.2;
      border-bottom-width: 0;
      font-size: .96rem;

      &:required:invalid {
        color: lightgray;
      }
    }

    .custom-select-mobile {
      height: 2.5rem !important;
      font-size: 0.96rem !important;

      &:focus {
        border-color: transparent !important;
        box-shadow: none !important;
      }
    }
  }

  .search-filters-mobile-label-padding {
    padding-top: 9px;
    padding-bottom: 9px;
  }

  .item-title {
    font-size: .88rem;
  }

  .custom-select-navbar-title {
    font-weight: 500;
    color: #181c21e6;
  }
}

.search-filters-desktop {
  .search-filters-desktop-title {
    .search-filters-desktop-state-seal {
      padding: 1rem 1rem 0 1rem;
    }
  }

  .search-filters-desktop-under-logo {
    .search-filters-desktop-location-container {
      .search-style {
        margin-bottom: -12.484px;
        top: -7.484px
      }

      .search-current-style {
        top: 36px;
      }

      .search-filters-desktop-location {
        a {
          .search-filters-desktop-location-text {
            color: #ffffff;
            font-size: 1rem;
            opacity: 0.4;

            &:hover {
              opacity: 1;
            }

            &.disabled {
              color: #cccccc;
            }
          }
        }
      }
    }

    .search-filters {
      tr {
        td {
          &:first-child {
            width: 5.5rem;
            height: 2.2rem;
          }

          &:last-child {
            .switcher-indicator {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
