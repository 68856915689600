$danger: #C1272D;

@import "animate.css/animate.css";
@import "app/app.shared.variables";
@import "styles-custom-extendable";
@import "swiper/swiper-bundle.min.css";
@import "./vendor/libs/quill/typography";
@import "./vendor/libs/quill/editor";
@import "styles/ngb-typeahead";
@import "styles/search-filters";

:root {
  --swiper-pagination-bullet-size: 6px;
  --swiper-pagination-bullet-width: 6px;
  --swiper-pagination-bullet-height: 6px;
}

html {
  overflow-x: hidden;
}

::ng-deep .ps__rail-y, ::ng-deep .ps__rail-x {
  opacity: 0.6;
}

::ng-deep .ps__thumb-y, ::ng-deep .ps__thumb-x {
  background-color: #cccccc;
}

body.modal-open {
  overflow: hidden;
  padding-right: 0 !important;
}

ngb-timepicker {
  .btn-link {
    border: 0;
    color: #000 !important;
    opacity: .4;

    &:hover,
    &:focus,
    &:active {
      opacity: .8;
    }

    &[disabled] {
      opacity: .1 !important;
    }
  }

  .chevron::before {
    border-top-width: 1px !important;
    border-right-width: 1px !important;
  }

  .ngb-tp {
    align-items: start !important;

    .ngb-tp-spacer {
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
    }

    .ngb-tp-input-container {
      width: 2em;

      .btn-link:first-child {
        display: none;
      }
    }
  }
}

.toast-container {
  z-index: 1080 !important;

  &.toast-modal {
    z-index: 1300 !important;
  }
}

.popover-warning .popover-body,
.popover-warning > .popover .popover-body,
.ngb-popover-warning + ngb-popover-window .popover-body {
  color: #000000 !important;
}

.ngb-popover-meal + ngb-popover-window .popover-body {
  font-size: $font-size-base;
}

.scale-095 {
  transform: scale(0.95);
  -moz-transform: scale(0.95);
}

.scale-08 {
  transform: scale(0.8) !important;
  -moz-transform: scale(0.8) !important;
}

.scale-085 {
  transform: scale(0.85) !important;
  -moz-transform: scale(0.85) !important;
}

.scale-09 {
  transform: scale(0.9) !important;
  -moz-transform: scale(0.9) !important;
}

.scale-07 {
  transform: scale(0.7) !important;
  -moz-transform: scale(0.7) !important;
}

.scale-75 {
  transform: scale(0.75);
  -moz-transform: scale(0.75);
}

.scale-82 {
  transform: scale(0.82);
  -moz-transform: scale(0.82);
}

.scale-065 {
  transform: scale(0.65) !important;
  -moz-transform: scale(0.65) !important;
}

.scale-045 {
  transform: scale(0.45);
  -moz-transform: scale(0.45);
}

.scale-055 {
  transform: scale(0.55);
  -moz-transform: scale(0.55);
}

.dark-modal-header {
  background-color: #363636;
  color: #ffffff;
  padding: 0.8rem 1.4rem !important;
}

.sw {
  padding: 20px;

  .sw-element {
    margin: 0 auto;
    height: 4px;
    background-color: rgba(219, 219, 219, 1);
    width: 48px;
    border-radius: 2px;
  }
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rotate--45-scale-08 {
  transform: rotate(45deg) scale(.8);
}

.rotate-45 {
  transform: rotate(45deg);
}

.rotate-135 {
  transform: rotate(135deg) scale(1.3) !important;
}

.w-inherit {
  width: inherit !important;
}

$slid-percent: 60%;

.slideDown {
  -webkit-animation-name: slideDown;
  animation-name: slideDown;
}

@-webkit-keyframes slideDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    -webkit-transform: translate3d(0, $slid-percent, 0);
    transform: translate3d(0, $slid-percent, 0);
  }
}

@keyframes slideDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    -webkit-transform: translate3d(0, $slid-percent, 0);
    transform: translate3d(0, $slid-percent, 0);
  }
}

.slideUp {
  -webkit-animation-name: slideUp;
  animation-name: slideUp;
}

@-webkit-keyframes slideUp {
  from {
    -webkit-transform: translate3d(0, $slid-percent, 0);
    transform: translate3d(0, $slid-percent, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideUp {
  from {
    -webkit-transform: translate3d(0, $slid-percent, 0);
    transform: translate3d(0, $slid-percent, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

/**
 * ----------------------------------------
 * animation rotate-vert-center
 * ----------------------------------------
 */
@-webkit-keyframes rotate-vert-center {
  0% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }
  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}

@keyframes rotate-vert-center {
  0% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }
  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}

.rotate-vert-center {
  -webkit-animation: rotate-vert-center 0.7s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
  animation: rotate-vert-center 0.7s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}

.container-food {
  background-color: white;

  @media (max-width: 576px) {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  & > .min-height-auto {
    margin-bottom: 1.75rem;

    .card-menu,
    .card-food {
      min-height: auto !important;
    }
  }

  & > .row {
    .card-menu,
    .card-food {
      min-height: calc(100vh - #{$header-navbar-height} - #{$footer-navbar-height});

      @supports (min-height: $safe-area-inset-bottom-env) {
        min-height: calc(100vh - #{$header-navbar-height} - #{$footer-navbar-height} - #{$safe-area-inset-bottom-env});
      }

      @supports (min-height: $safe-area-inset-bottom-constant) {
        min-height: calc(100vh - #{$header-navbar-height} - #{$footer-navbar-height} - #{$safe-area-inset-bottom-constant});
      }
    }
  }
}

.navbar-bottom-bg {
  &:after {
    content: '';
    width: 100%;
    height: $header-navbar-height;
    background: none;
    bottom: -44px;
    position: absolute;
  }
}

.visibility-hidden {
  visibility: hidden;
}

.border-radius-none {
  border-radius: 0 !important;
}

.d-inline-block-vertical-middle {
  display: inline-block;
  vertical-align: middle;
}

.badge-outline-white {
  background-color: transparent;
  -webkit-box-shadow: 0 0 0 1px white inset;
  box-shadow: 0 0 0 1px white inset;
  color: white;
}

.switcher-ig {
  min-height: 1.45rem;
}

.fa-1-5x {
  font-size: 1.5em !important;
}

.btn-dropdown-box-shadow-none:active,
.btn-dropdown-box-shadow-none.active,
.show > .btn-dropdown-box-shadow-none.dropdown-toggle {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.icon-a {
  display: inline-block;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0 solid transparent;
  line-height: 1.47;
  border-radius: 0.125rem;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  font-size: .9rem;
  text-transform: uppercase;
  color: #4E5155;

  &:focus {
    outline: none;
  }
}

.btn {
  &.btn-dark-shadow {
    &:focus {
      background: none !important;
    }

    &:active {
      background: rgba(78, 81, 85, 0.15) !important;
    }
  }

  &.btn-primary-shadow {
    &:focus {
      background: none !important;
    }

    &:active {
      background: rgba(38, 180, 255, 0.15) !important;
    }
  }
}

.dropdown-item {
  font-weight: 400;
  font-size: 15.36px;

  &.dropdown-item-primary-shadow {
    &:focus {
      background: rgba(38, 180, 255, 0.15) !important;
    }

    &:active {
      background: rgba(38, 180, 255, 0.15) !important;
    }
  }
}

.bg-cover-transparent {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
}

.w-92 {
  width: 92% !important;
}

.w-83 {
  width: 83% !important;
}

.w-88 {
  width: 88% !important;
}

.w-93 {
  width: 93% !important;
}

.w-50-p {
  width: 50% !important;
}

.w-40-p {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-60-p {
  width: 60% !important;
}

.w-90 {
  width: 90% !important;
}

.mt-260px {
  margin-top: 260px !important;
}

.mt-n5px {
  margin-top: -5px !important;
}

.mt-6px {
  margin-top: 6px !important;
}

.mt-1-2rem {
  margin-top: 1.2rem;
}

.mt-1-7rem {
  margin-top: 1.7rem;
}

.mt-1-8rem {
  margin-top: 1.8rem;
}

.mt-7-4rem {
  margin-top: 7.4rem;
}

.custom-control {
  &.custom-radio {
    &.custom-radio-inverted {
      .custom-control-input ~ .custom-control-label::before {
        border-color: white;
      }

      .custom-control-input:checked ~ .custom-control-label::after {
        background: rgba(255, 255, 255, 0.15);
      }

      .custom-control-label::before {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23FFFFFF'/%3E%3C/svg%3E");
      }
    }

    &.custom-radio-secondary {
      .custom-control-input:checked ~ .custom-control-label::before {
        border-color: #8897AA;
      }

      .custom-control-input:checked ~ .custom-control-label::after {
        background: rgba(136, 151, 170, 0.15);
      }

      .custom-control-label::before {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%238897AA'/%3E%3C/svg%3E");
      }
    }
  }

  &.custom-checkbox {
    &.custom-checkbox-secondary {
      .custom-control-input:checked ~ .custom-control-label::before {
        background-color: #8897AA99;
        border-color: #8897AA99;
      }

      .custom-control-input:checked ~ .custom-control-label::after {
        background: rgba(136, 151, 170, 0.15);
      }
    }

    &.custom-checkbox-white-border {
      .custom-control-input:checked ~ .custom-control-label::before {
        background-color: #8897AA99;
        border-color: white !important;
      }

      .custom-control-input:checked ~ .custom-control-label::after {
        background: rgba(136, 151, 170, 0.15);
        border-color: white !important;
      }
    }

    &.custom-checkbox-dark {
      .custom-control-input:checked ~ .custom-control-label::before {
        background-color: rgba(24, 28, 33, 0.9);
        border-color: rgba(24, 28, 33, 0.9);
      }

      .custom-control-input:checked ~ .custom-control-label::after {
        background: rgba(24, 28, 33, 0.5);
      }
    }
  }
}

.max-width {
  max-width: 576px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 576px) {
    padding-left: 0;
    padding-right: 0;

    .container-row {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
}

.btn-circle {
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  padding: 0;
  border-radius: 50%;

  i {
    position: relative;
  }

  &.btn-circle-sm {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 0.9rem;
  }

  &.btn-circle-lg {
    width: 55px;
    height: 55px;
    line-height: 55px;
    font-size: 1.1rem;
  }

  &.btn-circle-xl {
    width: 70px;
    height: 70px;
    line-height: 70px;
    font-size: 1.3rem;
  }
}

.has-navbar-top-margin {
  margin-top: $header-navbar-height !important;
}

.has-navbar-top {
  padding-top: $header-navbar-height !important;
}

.has-navbar-bottom {
  padding-bottom: $footer-navbar-height !important;
}

.has-navbar-both {
  padding-top: $header-navbar-height !important;
  padding-bottom: $footer-navbar-height !important;
}

.box-shadow-none {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.container-row {
  margin-left: -1rem;
  margin-right: -1rem;
}

.bg-black {
  background-color: #000;
}

.bg-light-gray {
  background-color: rgba(24, 28, 33, 0.15) !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-size-1rem {
  font-size: 1rem !important;
}

.font-size-small {
  font-size: small !important;
}

.font-size-1-05rem {
  font-size: 1.05rem !important;
}

.font-size-2rem {
  font-size: 2rem !important;
}

.font-size-1-85rem {
  font-size: 1.85rem !important;
}

.font-size-1-5rem {
  font-size: 1.5rem !important;
}

.font-size-1-25rem {
  font-size: 1.25rem !important;
}

.font-size-1-3rem {
  font-size: 1.3rem !important;
}

.font-size-1-1rem {
  font-size: 1.1rem !important;
}

.font-size-1-08rem {
  font-size: 1.08rem !important;
}

.font-size-0-88rem {
  font-size: .88rem !important;
}

.font-size-0-96rem {
  font-size: .96rem !important;
}

.font-size-0-98rem {
  font-size: .98rem !important;
}

.font-size-0-9rem {
  font-size: .9rem !important;
}

.font-size-0-85rem {
  font-size: .85rem !important;
}

.font-size-0-8rem {
  font-size: .8rem !important;
}

.font-size-0-75rem {
  font-size: .75rem !important;
}

.font-size-2-3rem {
  font-size: 2.3rem !important;
}

.font-size-0-7rem {
  font-size: .7rem !important;
}

.title-standard {
  font-size: 1rem !important;
  font-weight: 500 !important;
}

.title-secondary {
  font-size: 1rem !important;
  font-weight: 500 !important;
  color: #babbbc;
}

.scaleY--1 {
  -webkit-transform: scaleY(-1);
  -moz-transform: scaleY(-1);
  -ms-transform: scaleY(-1);
  -o-transform: scaleY(-1);
  transform: scaleY(-1);
}

#mmmm-loader {
  animation: loader-animation 1.5s cubic-bezier(.2, .5, .4, .7) infinite;
  animation-delay: .1s;
  background: linear-gradient(45deg, #ffffffb3, #ffffff80 25%, #00000080 50%, #000000b3 75%, #ffffff80);
  display: none;
  height: 2px;
  position: fixed;
  top: 0;
  transform: translateX(100%);
  transition: all .3s ease-in-out 0s;
  width: 100%;
  z-index: 1060;
}

@keyframes loader-animation {
  0% {
    transform: translateX(-100%)
  }

  50% {
    transform: translateX(0)
  }

  to {
    transform: translateX(100%)
  }
}

@-webkit-keyframes leftToRight {
  0% {
    background-position: -400px 0
  }
  to {
    background-position: 400px 0
  }
}

@keyframes leftToRight {
  0% {
    background-position: -400px 0
  }
  to {
    background-position: 400px 0
  }
}

@-webkit-keyframes leftToRightThumbnail {
  0% {
    background-position: -64px 0
  }
  to {
    background-position: 64px 0
  }
}

@keyframes leftToRightThumbnail {
  0% {
    background-position: -64px 0
  }
  to {
    background-position: 64px 0
  }
}

.fa-1-7x {
  font-size: 1.7em !important;
}

.fa-1-8x {
  font-size: 1.8em !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-20 {
  opacity: 20% !important;
}

.opacity-40 {
  opacity: 40% !important;
}

.opacity-70 {
  opacity: 70% !important;
}

.opacity-80 {
  opacity: 80% !important;
}

/**
 * ----------------------------------------
 * animation slide-in-bck-center
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bck-center {
  0% {
    -webkit-transform: translateZ(600px);
    transform: translateZ(600px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes slide-in-bck-center {
  0% {
    -webkit-transform: translateZ(600px);
    transform: translateZ(600px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

body {
  overflow-y: hidden;
  background-color: #ffffff;

  @supports (padding-top: $safe-area-inset-top-env) {
    padding-top: $safe-area-inset-top-env;
  }

  @supports (padding-top: $safe-area-inset-top-constant) {
    padding-top: $safe-area-inset-top-constant;
  }
}

app-root {
  overflow-y: auto;
}

.badge-white {
  background-color: white;
  color: black;
}

.badge-orange {
  background-color: #ff8250;
  color: white;
}

.shadow-block {
  border-radius: 50%;
  background: #4E5155;
  opacity: 0.55;
  filter: blur(17px);
  transform: scale(1, 0.35);
  margin-left: auto;
  margin-right: auto;
  width: 100px;
  height: 66px;
}

.input-file-absolute {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  z-index: 1;
}

.cursor-auto {
  cursor: auto !important;
}

@mixin grab-cursor {
  cursor: grab;
}

@mixin grabbing-cursor {
  cursor: grabbing;
}

.drag-me {
  @include grab-cursor;


  &:active {
    @include grabbing-cursor;
  }
}

.cursor-grab {
  cursor: grab !important;
}

.h-100vh {
  height: 100vh;

  @supports (--css: variables) {
    height: calc(var(--vh, 1vh) * 100);
  }
}

.h-95vh {
  height: 95vh;

  @supports (--css: variables) {
    height: calc(var(--vh, 1vh) * 95);
  }
}

.min-h-100vh {
  min-height: 100vh;

  @supports (--css: variables) {
    min-height: calc(var(--vh, 1vh) * 100);
  }
}

.search-modal-content {
  height: calc(68vh + 42px) !important;
}

.input-group-text {
  &.input-group-text-inverted {
    border-color: rgba(255, 255, 255, 0.25);
  }
}

.collapse-icon {
  &.collapse-icon-inverted:after {
    border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.25);
    border-left: 0.0625rem solid rgba(255, 255, 255, 0.25);
  }
}

.ios-fixed-background {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  right: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  max-width: 576px;
  margin-left: auto;
  margin-right: auto;

  + .container {
    max-width: 576px;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.switcher {
  &.switcher-sm {
    .switcher-input {
      width: 0;
      height: 0;
    }

    .switcher-indicator, .form-group-sm .switcher .switcher-indicator {
      top: 0.1275rem !important;
    }
  }
}

.backdrop-filter-brightness-0-15 {
  backdrop-filter: brightness(0.15);
  -webkit-backdrop-filter: brightness(0.15);
}

.backdrop-filter-brightness-0-25 {
  backdrop-filter: brightness(0.25);
  -webkit-backdrop-filter: brightness(0.25);
}

.backdrop-filter-brightness-0-3 {
  backdrop-filter: brightness(0.3);
  -webkit-backdrop-filter: brightness(0.3);
}

.backdrop-filter-brightness-0-35 {
  backdrop-filter: brightness(0.35);
  -webkit-backdrop-filter: brightness(0.35);
}

.backdrop-filter-brightness-0-6 {
  backdrop-filter: brightness(0.6);
  -webkit-backdrop-filter: brightness(0.6);
}

.backdrop-filter-brightness-0-65 {
  backdrop-filter: brightness(0.65);
  -webkit-backdrop-filter: brightness(0.65);
}

.backdrop-filter-brightness-0-7 {
  backdrop-filter: brightness(0.7);
  -webkit-backdrop-filter: brightness(0.7);
}

.backdrop-filter-brightness-0-8 {
  backdrop-filter: brightness(0.8);
  -webkit-backdrop-filter: brightness(0.8);
}

.backdrop-filter-brightness-0-85 {
  backdrop-filter: brightness(0.85);
  -webkit-backdrop-filter: brightness(0.85);
}

.backdrop-filter-brightness-0-5 {
  backdrop-filter: brightness(0.5);
  -webkit-backdrop-filter: brightness(0.5);
}

.backdrop-filter-brightness-1 {
  backdrop-filter: brightness(1);
  -webkit-backdrop-filter: brightness(1);
}

.backdrop-filter-blur-5 {
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.backdrop-filter-blur-7 {
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
}

.backdrop-filter-blur-10 {
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.container-backdrop {
  min-height: calc(100vh - #{$header-navbar-height} - #{$footer-navbar-height});

  @supports (--css: variables) {
    min-height: calc((var(--vh, 1vh) * 100) - #{$header-navbar-height} - #{$footer-navbar-height});
  }
}

.image-cover {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}

.text-default {
  color: #4e5155 !important;

  &:focus-visible {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
  }
}

.position-absolute-0 {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
}

.position-fixed-0 {
  position: fixed !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
}

.text-overflow-crop {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-y {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.btn-outline-dark {
  &.btn-outline-white {
    border: 1px solid #fff !important;
    background: transparent;
    color: #fff;
    box-shadow: none;
  }

  &.btn-outline-white:hover {
    border-color: rgba(0, 0, 0, 0) !important;
    background: #fff;
    color: #666
  }

  &.btn-outline-white:focus,
  &.btn-outline-white.focus {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.4) !important;
  }

  &.btn-outline-red {
    border: 1px solid #fff !important;
    background: transparent;
    color: #fff;
    box-shadow: none;
  }

  &.btn-outline-red:hover {
    border-color: rgba(0, 0, 0, 0) !important;
    background: #C1272D;
    color: #fff;
  }

  &.btn-outline-red:focus,
  &.btn-outline-white.focus {
    box-shadow: 0 0 0 3px #C1272D !important;
  }
}

.background-none {
  background: none;
}

.custom-select {
  &.custom-select-no-border {
    border-bottom-width: 0 !important;

    &:focus {
      box-shadow: none !important;
    }
  }

  &.custom-select-no-arrow {
    background: transparent !important;
  }

  &:disabled {
    color: #a3a4a6 !important;
  }
}

.bg-container {
  background-color: #e7e7e7 !important;
}

.form-control-style {
  width: calc((100vw - (65vw - 8.75rem) - 74px - var(--scrollbar-width)));
  height: 38px !important;
  border-radius: 2rem !important;

  &:focus,
  &:hover {
    background-color: transparent !important;
  }
}

.form-control-input:focus {
  border-color: #FFFFFF;
  box-shadow: none;
}

.input-group {
  &.input-group-no-border {
    input.form-control {
      border: none !important;

      &:focus {
        border: none !important;
        box-shadow: none !important;
      }
    }

    .input-group-text {
      border: none !important;
    }
  }
}

.form-control {
  &.form-control-no-border {
    border: none !important;

    &:focus {
      border: none !important;
      box-shadow: none !important;
    }
  }
}

.textarea-transparent {
  background: transparent;
  width: 100%;
  border: none;
  outline: none;
  color: white;
}

input[type=number] {
  &.form-control {
    &.form-control-3-digit {
      width: 3rem;

      @supports (--css: variables) {
        width: calc(3rem + var(--inaw, 0rem));
      }
    }
  }
}

.form-row {
  flex-wrap: nowrap;

  .form-group {
    &.form-group-col {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

.form-control-spicy {
  margin: -0.47rem;
}

.margin-n0-2rem {
  margin: -0.2rem;
}

#toast-container > div.toast-desktop {
  width: 28rem !important;
}

.scale-0-9 {
  transform: scale(0.9);
  -moz-transform: scale(0.9);
}

.scale-0-8 {
  transform: scale(0.8);
  -moz-transform: scale(0.8);
}

.scale-0-7 {
  transform: scale(0.7);
  -moz-transform: scale(0.7);
}

.scale-0-5 {
  transform: scale(0.5) !important;
  -moz-transform: scale(0.5) !important;
}

.scale-4 {
  transform: scale(4) !important;
  -moz-transform: scale(4) !important;
}

.scale-6 {
  transform: scale(6) !important;
  -moz-transform: scale(6) !important;
}

.scale-2 {
  transform: scale(2) !important;
  -moz-transform: scale(2) !important;
}

.scale-1_07 {
  transform: scale(1.07) !important;
  -moz-transform: scale(1.07) !important;
}

.scale-1_4 {
  transform: scale(1.4) !important;
  -moz-transform: scale(1.4) !important;
}

.scale-1_5 {
  transform: scale(1.5) !important;
  -moz-transform: scale(1.5) !important;
}

.scale-1_6 {
  transform: scale(1.6);
  -moz-transform: scale(1.6);
}

.scale-1_2 {
  transform: scale(1.2);
  -moz-transform: scale(1.2);
}

.scale-1_25 {
  transform: scale(1.25);
  -moz-transform: scale(1.25);
}

.scale-1_15 {
  transform: scale(1.15);
  -moz-transform: scale(1.15);
}

.scale-1_1 {
  transform: scale(1.1);
  -moz-transform: scale(1.1);
}

.scale-1_85 {
  transform: scale(1.85);
  -moz-transform: scale(1.85);
}

.scale-2_1 {
  transform: scale(2.1);
  -moz-transform: scale(2.1);
}

.scale-2_5 {
  transform: scale(2.5);
  -moz-transform: scale(2.5);
}

.scale-1_3 {
  transform: scale(1.3) !important;
  -moz-transform: scale(1.3) !important;
}

.scale-1_35 {
  transform: scale(1.35) !important;
  -moz-transform: scale(1.35) !important;
}

.scale-3 {
  transform: scale(3);
  -moz-transform: scale(3);
}

.scale-3-5 {
  transform: scale(3.5) !important;
  -moz-transform: scale(3.5) !important;
}

.scale-7 {
  transform: scale(7);
  -moz-transform: scale(7);
}

.scale-5-5 {
  transform: scale(5.5);
  -moz-transform: scale(5.5);
}

.scale-9 {
  transform: scale(9);
  -moz-transform: scale(9);
}

.w-33 {
  width: 33.3% !important;
}

.w-30 {
  width: 30% !important;
}

.d-flex {
  &.d-flex-row {
    margin-left: -5px !important;
    margin-right: -5px !important;
  }
}

.phantom-block {
  margin-top: -44px;
  height: 44px;
  pointer-events: none;
}

.phantom-block-desktop {
  margin-top: -1.7rem;
  height: 1.7rem;
  pointer-events: none;

  &.phantom-block-desktop-explore {
    margin-top: -3.625rem;
    height: 3.625rem;
  }
}

.modal-top-standard {
  .modal-top-standard-header {
    height: $header-navbar-height;
  }

  .modal-top-standard-body {
    display: flex;
    flex-direction: column;
    height: calc(#{$modal-standard-height} + #{$header-navbar-height});

    .modal-top-standard-content {
      padding: 1rem;
      overflow-y: auto;
    }

    .modal-top-standard-footer {
      padding: 1rem;
      margin-top: auto;
    }
  }
}

.col-2-5 {
  -ms-flex: 0 0 20% !important;
  flex: 0 0 20% !important;
  max-width: 20% !important;
}

.h-60 {
  height: 60% !important;
}

.h-590px {
  height: 590px !important;
}

.min-h-110px {
  min-height: 110px !important;
}

.h-23-5px {
  height: 23.5px;
}

.rest-name {
  line-height: 1.3;
  font-size: 1.85rem;
  font-weight: 600;
}

.mobile-second-name {
  font-size: 1rem;
  margin-top: 0.5rem;
  font-weight: 400;
  line-height: 1.3;
}

.restaurant-hours-periods {
  width: 100%;

  .restaurant-hours-td {
    padding: 0.25rem 0.5rem;
  }

  .regular-hours-empty {
    width: 1rem;
  }

  .restaurant-hours-value {
    text-align: left;
  }

  &.restaurant-hours-preview {
    .restaurant-hours-title {
      width: 47%;
    }

    .restaurant-hours-value {
      width: 53%;
    }
  }

  &.restaurant-hours-main {
    .restaurant-hours-title {
      width: 6.8rem;
    }

    .restaurant-hours-title-regular {
      width: 6.8rem;
    }

    .restaurant-hours-value {
      width: $hours;
    }
  }

  &.restaurant-hours-edit {
    .restaurant-hours-title {
      padding: 0.25rem 0.5rem 0.25rem 0;
    }

    .restaurant-hours-value {
      width: $hours !important;
    }

    .restaurant-hours-empty {
      padding: 0.25rem 0 0.25rem 0.5rem;
      width: 1rem !important;
    }

    &.restaurant-hours-edit-special {
      .restaurant-hours-title {
        width: calc(((100% - #{$add-date-width}) / 2) - .5rem);
      }
    }

    &.restaurant-hours-edit-regular {
      .restaurant-hours-title {
        width: calc(((100% - #{$add-hours-width}) / 2) - .5rem);
      }
    }
  }
}

.restaurant-hours-desktop {
  .restaurant-hours-periods {
    &.restaurant-hours-main {
      .restaurant-hours-title {
        width: 7rem !important;
      }

      .restaurant-hours-value {
        width: $hours !important;
      }
    }
  }
}

.noUi-inverted {
  .noUi-target {
    background: rgba(255, 255, 255, 0.25) !important;

    .noUi-base {
      .noUi-connect {
        background: rgba(255, 255, 255, 0.5) !important;
      }
    }
  }
}

.switcher-toggle {
  display: block;
  cursor: pointer;

  .switcher-toggle-label {
    opacity: 50%;
  }

  .switcher-toggle-input {
    position: absolute;
    z-index: -1;
    margin: 0;
    padding: 0;
    opacity: 0;
  }

  .switcher-toggle-input:checked ~ .switcher-toggle-label {
    opacity: 1;
  }
}

.color-transparent {
  color: transparent !important;
}

.search-filters-desktop {
  .dropdown-item:not(.disabled).active {
    background: #e9ebee !important;
    color: black !important;
  }

  .ng-select {
    &.dark {
      &.ng-select-dropdown {
        .ng-dropdown-panel {
          .ng-option {
            line-height: 1.54 !important;
            padding: 0.3125rem 0.875rem !important;
          }
        }
      }
    }
  }
}

.custom-select {
  &.hide-arrow {
    background: none;
  }
}

.ng-select {
  &.auto-grow {
    max-width: inherit;

    .ng-dropdown-panel {
      width: auto;
    }
  }

  &.hide-arrow {
    .ng-arrow-wrapper,
    .ng-clear-wrapper {
      display: none !important;
    }

    .ng-select-container {
      border-bottom: none !important;
      transition: none !important;
      box-shadow: none !important;
    }
  }

  &.ng-select-dropdown {
    .ng-dropdown-panel {
      min-width: 8rem;
      padding: 0.3125rem 0;
      margin: 0.125rem -13px 0;
      font-size: 0.96rem;
      color: #000;
      text-align: left;
      list-style: none;
      background-clip: padding-box;
      backdrop-filter: blur(5px);
      background-color: rgba(24, 28, 33, 0.9) !important;
      color: white !important;
      border: 0 solid rgba(24, 28, 33, 0.15);
      border-radius: 0.125rem;
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .7), 0 4px 5px 0 rgba(0, 0, 0, .5), 0 1px 10px 0 rgba(0, 0, 0, .3);

      &.ng-select-bottom {
        margin-top: 0 !important;
      }

      .ng-dropdown-panel-items {
        max-height: 500px !important;

        .ng-option {
          &.ng-option-selected {
            background: none !important;
          }

          &.ng-option-marked {
            color: white !important;
            text-decoration: none !important;
            background-color: gray !important;
          }
        }
      }
    }
  }

  &.ng-select-inverted {
    .ng-dropdown-panel {
      color: #4E5155;

      .ng-dropdown-panel-items {
        .ng-option {
          &.ng-option-marked {
            color: #4E5155 !important;
            text-decoration: none !important;
            background-color: rgba(24, 28, 33, 0.03) !important;
          }
        }
      }
    }
  }

  &.ng-select-explore {
    .ng-select-container {
      cursor: pointer;

      .ng-value-container {
        font-size: 1rem;

        .ng-placeholder {
          color: #C1272D !important;
        }

        .ng-value {
          color: #4E5155 !important;
        }
      }
    }
  }

  &.ng-select-explore-search {
    .ng-select-container {
      cursor: pointer;

      .ng-value-container {
        font-size: 1rem;

        .ng-placeholder,
        .ng-value {
          color: white !important;
        }
      }
    }
  }

  &.ng-select-explore-search-title-flag {
    .ng-value-container {
      font-size: 7.5rem !important;
    }
  }

  &.ng-select-explore-search-title {
    .ng-select-container {
      min-height: 4rem;

      .ng-value-container {
        min-height: 4rem !important;
        font-size: 4rem;
        font-weight: bold;

        .ng-placeholder {
          line-height: 1;
        }

        .ng-value {
          line-height: 1;
        }

        .ng-input {
          padding: 0 !important;
          line-height: 1;
        }
      }
    }
  }

  &.ng-select-explore-search-title-under-logo {
    .ng-select-container {
      .ng-value-container {
        .ng-input {
          padding: 0 0 !important;
        }
      }
    }
  }

  &.ng-select-disabled {
    .ng-select-container {
      .ng-value-container {
        .ng-placeholder {
          color: #4E5155 !important;
        }
      }
    }
  }
}

.opacity-full-on-hover {
  &:not(.ng-select) {
    opacity: 1 !important;
  }

  &.ng-select {
    &.ng-select-opened {
      .ng-select-container {
        .ng-value-container {
          .ng-value {
            opacity: 1 !important;
          }
        }
      }
    }
  }
}

.opacity-05-to-1-on-hover {
  opacity: 0.5 !important;

  &:hover {
    opacity: 1 !important;
  }
}

.opacity-0-to-05-on-hover {
  opacity: 0 !important;

  &:hover {
    opacity: 0.5 !important;
  }
}

.opacity-01-to-1-on-hover {
  opacity: 0.1 !important;

  &:hover {
    opacity: 1 !important;
  }
}

.opacity-35-to-1-on-hover {
  opacity: 0.35 !important;

  &:hover {
    opacity: 1 !important;
  }
}

.opacity-45-to-1-on-hover {
  opacity: 0.45 !important;

  &:hover {
    opacity: 1 !important;
  }
}

.opacity-065-to-1-on-hover {
  opacity: 0.65 !important;

  &:hover {
    opacity: 1 !important;
  }
}

.opacity-07-to-1-on-hover {
  opacity: 0.7 !important;

  &:hover {
    opacity: 1 !important;
  }
}

.opacity-0-to-1-on-hover {
  opacity: 0 !important;

  &:hover {
    opacity: 1 !important;
  }
}

.opacity-01 {
  opacity: 0.1 !important;
}

[appngbdropdownonhover] {
  .dropdown-menu {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    overflow: auto;
  }
}

.popover {
  &.popover-report-closure {
    min-width: 10rem;

    .popover-header {
      background: none;
      border-bottom: none;
      font-weight: 400;
    }

    .popover-body {
      padding-top: 0;

      p {
        margin-bottom: 0;
      }

      .confirm-btns {
        .confirm-btn-container {
          width: 100%;
          flex-basis: auto;
        }
      }
    }
  }
}

.card {
  &.card-item-image {
    cursor: pointer;

    @extend .bg-cover-black;

    .card-item-image-title {
      position: absolute;
      bottom: 0;
      color: #fff;
      width: 100%;
      left: 0;
      padding: 0.25rem 0.5rem;
      text-shadow: 0 1px 0 #777777;
      z-index: 3;
    }

    .card-item-image-title-top {
      position: absolute;
      top: 0;
      color: #fff;
      width: 100%;
      left: 0;
      right: 0;
      padding: 0.25rem 0.5rem;
      text-shadow: 0 1px 0 #777777;
    }
  }
}

.text-shadow-70 {
  text-shadow: 0 1px 0 #00000070;
}

.font-weight-300 {
  font-weight: 300;
}

.opacity-30 {
  opacity: 30% !important;
}

.opacity-35 {
  opacity: 35%;
}

.opacity-40 {
  opacity: 40%;
}

.opacity-45 {
  opacity: 45%;
}

.opacity-60 {
  opacity: 60%;
}

.opacity-65 {
  opacity: 65%;
}

.btn-primary.md-btn-flat:active {
  background: transparent !important;
}

.px-0-85rem {
  padding-left: .85rem;
  padding-right: .85rem;
}

.px-n0-85rem {
  padding-left: -.85rem;
  padding-right: -.85rem;
}

.pt-3-5rem {
  padding-top: 3.5rem;
}

.my-28-8px {
  margin-top: 28.8px;
  margin-bottom: 28.8px;
}

.mb-3px {
  margin-bottom: 3px !important;
}

.mb-5px {
  margin-bottom: 5px !important;
}

.mb-1-7rem {
  margin-bottom: 1.7rem;
}

.mb-1-75rem {
  margin-bottom: 1.75rem;
}

.mb-4-75rem {
  margin-bottom: 4.75rem;
}

.mb-3-5rem {
  margin-bottom: 3.5rem;
}

.mb-5-5rem {
  margin-bottom: 5.5rem;
}

.mb-2-5rem {
  margin-bottom: 2.5rem;
}

.mb-n2-rem {
  margin-bottom: -2rem;
}

.mb-15rem {
  margin-bottom: 15rem;
}

.mt-0-4rem {
  margin-top: 0.4rem !important;
}

.mt-0-7rem {
  margin-top: 0.7rem !important;
}

.mt-0-9rem {
  margin-top: 0.9rem !important;
}

.mt-1-1rem {
  margin-top: 1.1rem !important;
}

.mt-1-2rem {
  margin-top: 1.2rem !important;
}

.mt-1-3rem {
  margin-top: 1.3rem !important;
}

.mt-1-4rem {
  margin-top: 1.4rem !important;
}

.mt-2-8rem {
  margin-top: 2.8rem !important;
}

.mt-11px {
  margin-top: 11px !important;
}

.mt-44px {
  margin-top: 44px !important;
}

.mt-n3px {
  margin-top: -3px !important;
}

.mt-n15px {
  margin-top: -15px !important;
}

.mt-3-5 {
  margin-top: 35px !important;
}

.mt-4-5rem {
  margin-top: 4.5rem !important;
}

.mt-7px {
  margin-top: 7px !important;
}

.mt-10-5rem {
  margin-top: 10.5rem !important;
}

.mb-6-5rem {
  margin-bottom: 6.5rem;
}

.mb-0-85rem {
  margin-bottom: 0.85rem;
}

.mb-0-35rem {
  margin-bottom: 0.35rem;
}

.mb-0-6rem {
  margin-bottom: 0.6rem;
}

.ml-0-4rem {
  margin-left: .4rem;
}

.ml-5px {
  margin-left: 5px;
}

.ml-0-4rem {
  margin-left: .4rem;
}

.ml-2rem {
  margin-left: 2rem;
}

.ml-n2rem {
  margin-left: -2rem;
}

.ml-10px {
  margin-left: 10px;
}

.mx-0-85rem {
  margin-left: 0.85rem;
  margin-right: 0.85rem;
}

.mr-0-85rem {
  margin-right: 0.85rem;
}

.ml-0-85rem {
  margin-left: 0.85rem;
}

.mx-2rem {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mx-2-5rem {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.ml-2-4rem {
  margin-left: 2.4rem;
}

.ml-1-75rem {
  margin-left: 1.75rem;
}

.ml-3px {
  margin-left: 3px !important;
}

.ml-2_5rem {
  margin-left: 2.5rem;
}

.ml-0-7rem {
  margin-left: 0.7rem;
}

.ml-0-8rem {
  margin-left: 0.8rem;
}

.ml-4rem {
  margin-left: 4rem;
}

.ml-n2px {
  margin-left: -2px;
}

.ml-n3px {
  margin-left: -3px;
}

.ml-n2-6rem {
  margin-left: -2.6rem !important;
}

.ml-n1-9rem {
  margin-left: -1.9rem !important;
}

.ml-1-8rem {
  margin-left: 1.8rem !important;
}

.ml-20px {
  margin-left: 20px !important;
}

.ml-6rem {
  margin-left: 6rem;
}

.ml-5-5rem {
  margin-left: 5.5rem;
}

.ml-10rem {
  margin-left: 10rem !important;
}

.mt-n1px {
  margin-top: -1px !important;
}

.mt-3px {
  margin-top: 3px;
}

.mt-4px {
  margin-top: 4px;
}

.mt-3-1rem {
  margin-top: 3.1rem;
}

.mt-3-5rem {
  margin-top: 3.7rem;
}

.mt-2-5rem {
  margin-top: 2.5rem;
}

.mt-7rem {
  margin-top: 7rem;
}

.mt-7rem {
  margin-top: 7rem;
}

.mt-5rem {
  margin-top: 5rem;
}

.mx-1-75rem {
  margin-right: 1.75rem;
  margin-left: 1.75rem;
}

.mx-1-85rem {
  margin-right: 1.85rem !important;
  margin-left: 1.85rem !important;
}

.mx-3-5rem {
  margin-right: 3.5rem;
  margin-left: 3.5rem;
}

.mt-4rem {
  margin-top: 4rem;
}

.mt-13rem {
  margin-top: 13rem;
}

.mt-2rem {
  margin-top: 2rem;
}

.mt-31px {
  margin-top: 31px;
}

.my-2rem {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mt-n1px {
  margin-top: -1px;
}

.mt-n2px {
  margin-top: -2px !important;
}

.mt-2px {
  margin-top: 2px;
}

.mt-1px {
  margin-top: 1px;
}

.mb-2rem {
  margin-bottom: 2rem;
}

.mb-4rem {
  margin-bottom: 4rem;
}

.mr-2rem {
  margin-right: 2rem;
}

.mr-2-2rem {
  margin-right: 2rem;
}

.mr-1-75rem {
  margin-right: 1.75rem;
}

.mr-1-5rem {
  margin-right: 1.5rem;
}

.ml-1-6rem {
  margin-left: 1.6rem;
}

.mr-1-6rem {
  margin-right: 1.6rem;
}

.mr-n1-6rem {
  margin-right: -1.6rem;
}

.mr-03rem {
  margin-right: 0.3rem;
}

.mr-2-5rem {
  margin-right: 2.5rem;
}

.mr-n3px {
  margin-right: -3px;
}

.mr-5px {
  margin-right: 5px;
}

.mx-4rem {
  margin-right: 5rem;
  margin-left: 5rem;
}

.dropdown-menu-share {
  background-color: rgba(24, 28, 33, 0.03) !important;
  backdrop-filter: blur(5px);

  .dropdown-item {
    color: #FFFFFF;

    .icon-v2 {
      width: 32px;
      height: 28px;
      transform: scale(.8);
    }
  }
}

::ng-deep perfect-scrollbar {
  width: 100%;
  height: 100%;
}

::ng-deep .ps__rail-y, ::ng-deep .ps__rail-x {
  opacity: 0.6;
}

::ng-deep .ps__thumb-y, ::ng-deep .ps__thumb-x {
  background-color: yellow;
}

.mmmm-icon-rounded {
  width: 197px;
  height: 197px;

  .icon-v2 {
    &.mmmm-2x-red {
      transform: scale(2.7);
    }
  }
}

.mmmm-icon-rounded-small {
  margin-bottom: 3rem;
  width: 73px;
  height: 73px;

  .icon-v2 {
    &.mmmm-2x-red {
      transform: scale(1.7);
    }
  }
}

.layout-sidenav-hover {
  .sidenav {
    &.left-sidenav {
      background: #000000ab !important;
    }

    &.sidenav-user {
      background: #000000cc;
      backdrop-filter: blur(5px);

      .layout-user-sidenav-logo {
        display: block;
      }

      .layout-user-sidenav-menu {
        display: none;
      }

      &.sidenav-vertical {
        .sidenav-menu {
          .sidenav-link,
          .sidenav-header {
            padding-left: 2rem;
          }
        }
      }
    }
  }
}

.sidenav {
  &.sidenav-user {
    transform: none !important;
    transition: none !important;
    padding-top: 0.7rem;
    align-items: normal;
    width: 11rem;

    .sidenav-inner {
      flex: none;
    }

    .user-sidenav-logo {
      height: 51.1px;

      span {
        transform: scale(.7);
      }
    }

    .sidenav-item {
      &.active {
        background-color: #393d41;
      }

      a.sidenav-link {
        color: #fff;
      }
    }

    .layout-user-sidenav-logo {
      display: none;
    }

    .layout-user-sidenav-menu {
      display: block;
    }
  }

  &.sidenav-admin {
    background: #000000cc;
    backdrop-filter: blur(5px);
    width: 11rem;

    a.sidenav-link {
      color: #9d9fa1;
    }
  }

  .sidenav-item {
    .sidenav-link {
      cursor: pointer;
    }
  }
}

.navbar {
  &.navbar-admin {
    background-color: #000000cc !important;
    backdrop-filter: blur(5px);

    a {
      color: #9d9fa1;

      &:hover {
        color: white;
      }
    }
  }
}

.background-center-cover-no-repeat {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.layout-fixed:not(.layout-collapsed) .layout-container,
.layout-fixed-offcanvas:not(.layout-collapsed) .layout-container {
  //padding-left: 11rem;
}

.grayscale {
  backdrop-filter: grayscale(1);
}

#layout-background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  pointer-events: none;
}

.pointer-events-all {
  pointer-events: all;
}

.pointer-events-auto {
  pointer-events: auto;
}

app-explore .simplebar-scrollbar::before {
  background-color: #ccc !important;
}

.outline-none {
  outline: none !important;
}

.top-41vh {
  top: 41vh !important;
}

.top-2rem {
  top: 2rem !important;
}

.top-0 {
  top: 0 !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.right-0 {
  right: 0 !important;
}

.left-0 {
  left: 0 !important;
}

.top-input-modal {
  .top-input-modal-input {
    height: $header-navbar-height;
    border-bottom-width: 0 !important;
    outline: none !important;
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;

    &:focus {
      box-shadow: none;
    }

    &::placeholder {
      color: rgba(24, 28, 33, 0.9);
    }
  }

  ngb-typeahead-window {
    &.search-location-popup {
      width: 100%;
      border-radius: 0;
      box-shadow: none;

      .dropdown-menu .active > a,
      .dropdown-menu .active > a:hover {
        color: #333333;
        text-decoration: none;
        background-color: #0081c2;
      }
    }
  }
}

.search-filters-mobile-location-input {
  &::placeholder {
    color: rgba(24, 28, 33, 0.9);
    opacity: 0.7;

    &:hover {
      color: black !important;
    }
  }
}

.search-filters-desktop-location-input {
  color: black;
  background-color: transparent !important;

  &::placeholder {
    color: #fff !important;
    opacity: 1 !important;
  }

  ngb-typeahead-window .dropdown-item {
    padding-left: 40px !important;
  }
}

.brightness-05-to-1 {
  backdrop-filter: brightness(0.5) !important;

  &:hover {
    backdrop-filter: brightness(1) !important;
  }
}

.position-absolute-heart {
  position: absolute !important;
  top: 43% !important;
  right: 37% !important;
}

.position-absolute-goal {
  position: absolute !important;
  top: 5px !important;
  right: 5px !important;
}

.brightness-1-to-05 {
  backdrop-filter: brightness(1) !important;

  &:hover {
    backdrop-filter: brightness(0.5) !important;
  }
}

.restaurant-hours-event-badge {
  background: #ffffff40;
  backdrop-filter: blur(6px);
  padding: .1rem .4rem;
  border-radius: 2px;
  margin-left: -0.4rem;
}

.border-bottom-radius-3 {
  border-bottom-left-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important;
}

.border-radius-5px {
  border-radius: 5px !important;
}

.border-radius-0-125rem {
  border-radius: 0.125rem !important;
}

.background-image-gradient, .background-time-gradient {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(255, 255, 255, 0) 20%,
      rgba(255, 255, 255, 0) 80%,
      rgba(0, 0, 0, 0.2) 100%
  );
}

.background-time-gradient {
  color: #ffffff;
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(150, 150, 150, 0.3) 20%,
      rgba(150, 150, 150, 0.3) 80%,
      rgba(0, 0, 0, 0.3) 100%
  ) !important;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.p-0_8rem {
  padding: 0.8rem !important;
}

.p-1-2rem {
  padding: 1.2rem !important;
}

.p-1-3rem {
  padding: 1.3rem !important;
}

.px-2-5rem {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

.pl-2-25rem-pr-1-75rem {
  padding-left: 2.25rem;
  padding-right: 1.75rem;
}

.pr-1-75rem {
  padding-right: 1.75rem;
}

.px-2rem {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.px-1-2rem {
  padding-left: 1.2rem !important;
  padding-right: 1.2rem !important;
}

.px-1-15rem {
  padding-left: 1.15rem !important;
  padding-right: 1.15rem !important;
}

.pl-0125 {
  padding-left: 0.125rem;
}

.pr-0125 {
  padding-right: 0.125rem;
}

.pr-1_75rem {
  padding-right: 1.75rem !important;
}

.pr-2-5-rem {
  padding-right: 2.5rem;
}

.pr-2-2-rem {
  padding-right: 2.2rem !important;
}

.px-2-5-rem {
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}

.pl-6px {
  padding-left: 6px !important;
}

.pl-0_8rem {
  padding-left: 0.8rem !important;
}

.pl-1_1rem {
  padding-left: 1.1rem !important;
}

.pl-1_2rem {
  padding-left: 1.2rem !important;
}

.pl-1_25rem {
  padding-left: 1.25rem !important;
}

.pl-1_7rem {
  padding-left: 1.7rem !important;
}

.pl-3px {
  padding-left: 6px !important;
}

.pl-3-5rem {
  padding-left: 3.5rem !important;
}

.pl-2rem {
  padding-left: 2rem !important;
}

.pl-8-4rem {
  padding-left: 8.4rem !important;
}

.pl-12px {
  padding-left: 12px !important;
}

.pl-0-75rem {
  padding-left: 0.75rem
}

.pl-0-4rem {
  padding-left: 0.4rem;
}

.padding-0-4rem {
  padding: 0 4rem;
}

.pb-2rem {
  padding-bottom: 2rem !important;
}

.pb-7px {
  padding-bottom: 7px !important;
}

.pb-2-5rem {
  padding-bottom: 2.5rem;
}

.pt-1-25rem {
  padding-top: 1.25rem;
}

.pt-1-5rem {
  padding-top: 1.5rem !important;
}

.pt-1-8rem {
  padding-top: 1.8rem !important;
}

.pt-44px {
  padding-top: $header-navbar-height;
}

.pt-5-5rem {
  padding-top: 5.5rem;
}

.pt-2rem {
  padding-top: 2rem;
}

.pt-3rem {
  padding-top: 3rem;
}

.pt-2-5rem {
  padding-top: 2.5rem;
}

.pt-44rem {
  padding-top: 44px !important;
}

.outside-mmmm {
  display: flex !important;
  align-items: center !important;
  right: 0 !important;
  top: 0 !important;
  bottom: 0;
  left: auto;
}

.outside-mmmm-logo {
  position: fixed;
  right: 1rem;
  top: 1rem;
  z-index: 1051;
  cursor: pointer;

  .mmmm-2x-gray,
  .mmmm-2x-red {
    &.smaller {
      transform: scale(0.7);
    }
  }
}

.swipe-slide {
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}

.swiper-wrapper {
  -webkit-transform-style: preserve-3d;
}

.kenburns-top {
  -webkit-animation: kenburns-top 40s linear reverse both;
  animation: kenburns-top 40s linear reverse both;
}

@-webkit-keyframes kenburns-top {
  0% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 16%;
    transform-origin: 50% 16%;
  }
  100% {
    -webkit-transform: scale(1.25) translateY(-15px);
    transform: scale(1.25) translateY(-15px);
    -webkit-transform-origin: top;
    transform-origin: top;
  }
}

@keyframes kenburns-top {
  0% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 16%;
    transform-origin: 50% 16%;
  }
  100% {
    -webkit-transform: scale(1.25) translateY(-15px);
    transform: scale(1.25) translateY(-15px);
    -webkit-transform-origin: top;
    transform-origin: top;
  }
}

.tooltip-inner {
  min-width: 150px !important;
  max-width: 400px !important;
}

.custom-select {
  [label] {
    color: #4E5155;
  }
}

.mmmm-icon-position {
  position: fixed;
  top: 14rem;
  right: 0;
  left: 0;
  z-index: 1030;
}

.z-index-0 {
  z-index: 0 !important;
}

.z-index-1 {
  z-index: 1 !important;
}

.z-index-3 {
  z-index: 3 !important;
}

.z-index-10 {
  z-index: 10 !important;
}

.pb-3-rem {
  padding-bottom: 3rem;
}

.pb-085-rem {
  padding-bottom: 0.85rem;
}

.pb-065-rem {
  padding-bottom: 0.65rem;
}

.pb-01-rem {
  padding-bottom: 0.1rem;
}

.pb-10-rem {
  padding-bottom: 10rem;
}

.pb-30-rem {
  padding-bottom: 30rem;
}

.w-60 {
  width: 60% !important;
}

.w-40 {
  width: 40% !important;
}

.w-70 {
  width: 70% !important;
}

.w-75 {
  width: 75% !important;
}

.w-55 {
  width: 55%;
}

.w-59-5rem {
  width: 59.5rem !important;
}

.w-6rem {
  width: 6rem !important;
}

.w-9rem {
  width: 9rem !important;
}

.w-20px {
  width: 20px !important;
}

.w-61-5px {
  width: 61.5px !important;
}

.w-13rem {
  width: 13rem !important;
}

.w-15rem {
  width: 15rem !important;
}

.w-8 {
  width: 8% !important;
}

.w-25 {
  width: 25% !important;
}

.w-31px {
  width: 31px !important;
}

.w-65rem {
  width: 65rem !important
}

.rotate-90 {
  transform: rotate(90deg) !important;
}

.rotate-180 {
  transform: rotate(180deg) !important;
}

.rotate--90 {
  transform: rotate(-90deg) !important;
}

.canvas-background {
  position: absolute;
  top: 0;
  left: 0;

  canvas {
    position: absolute;
  }
}

.rest-modal-height {
  height: calc(100vh - 58px) !important;
}

.h100vh-n2rem {
  height: calc(100vh - 2rem);
}

.h100-n2rem {
  height: calc(100% - 2rem);
}

.h-96vh {
  height: 96vh !important;
}

.h-80vh {
  height: 80vh;
}

.h-60vh {
  height: 60vh;
}

.h-55vh {
  height: 55vh;
}

.h-55px {
  height: 55px;
}

.h-120px {
  height: 120px !important;
}

.h-37-6px {
  height: 37.6px !important;
}

.h-44px {
  height: 44px !important;
}

.h-36px {
  height: 36px !important;
}

.h-20rem {
  height: 20rem !important;
}

.background-transparent {
  background: transparent;
}

.swiper-wrapper {
  padding-top: env(safe-area-inset-top);
  width: 100%;
  height: 100%;

  .swipe-slide {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0;
  }
}

.line-height-1-3 {
  line-height: 1.3;
}

.bg-dark-on-modal {
  background-color: rgba(24, 28, 33) !important;
}

.bg-black-70 {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.bg-f3 {
  background-color: #f3f3f3 !important;
}

.app-background-url {
  &.ios-fixed-background {
    padding-bottom: 0 !important;

    @supports (--css: variables) {
      padding-bottom: calc(100vh - (var(--vh) * 100)) !important;
    }
  }
}

.modal.fade {
  opacity: 1;
}

.text-transparent {
  color: transparent !important;
}

.modal.fade .modal-dialog, .modal.in .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

@keyframes center-modal-animation {
  0% {
    opacity: 0;
    transform: scale(1.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.slide-in-bottom {
  -webkit-animation: slide-in-bottom 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-bottom 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.py-0-35 {
  padding-bottom: 0.35rem;
  padding-top: 0.35rem;
}

.text-black {
  color: black !important;
}

.text-align-left {
  text-align: left !important;
}

.text-decoration-underline {
  text-decoration: underline;
}

.row-items {
  margin: 0 -0.125rem !important;

  .col-item {
    padding: 0 .125rem;
  }
}

.classic-item-status {
  position: absolute;
  right: 1.5rem;
  top: 1rem;
}

.classic-image {
  &.classic-image-mobile {
    .classic-image-container {
      .classic-item-text {
        font-weight: 700;
      }
    }
  }

  .classic-image-container {
    position: relative;

    .classic-item-status {
      position: absolute;
      right: 1.5rem;
      top: 1rem;
    }

    .classic-image-icon {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 1rem;
    }

    .classic-item-text {
      position: absolute;
      bottom: 0;
      color: #fff;
      width: 100%;
      z-index: 10;
      left: 0;
      display: flex;
      justify-content: space-between;

      .classic-item-right {
        align-self: flex-end;
        text-align: right;

        @extend .font-size-1-05rem;
      }
    }
  }
}

.safe-area-top {
  margin-top: env(safe-area-inset-top);
}

.safe-area-padding-top {
  padding-top: env(safe-area-inset-top);
}

.safe-area-bottom {
  margin-bottom: env(safe-area-inset-bottom);
}

.safe-area-padding-bottom {
  padding-bottom: env(safe-area-inset-bottom);
}

.post-new-food-input-group {
  ngb-typeahead-window {
    &.dropdown-menu {
      box-shadow: none;
      background-color: rgba(24, 28, 33, 0.9);
      color: white;
      width: 100%;
      padding-left: 16px;

      .dropdown-item {
        padding-left: 0;
        padding-right: 0;
        color: white;

        &.active {
          background: none;
        }
      }
    }
  }
}

.btn{
  .btn-default.md-btn-flat {
    color: #FFFFFF9e !important;

    :hover {
      color: #FFFFFF !important;
    }
  }
}

.btn-group-gray {
  background: #e3e3e3;
  padding: .125rem;
  border-radius: 0.25rem;

  .btn,
  .btn:active {
    box-shadow: none;
    color: #0e0e0e;
    background: none;
    padding: 0.25rem 1.5rem;
  }

  .btn:active,
  .btn.active {
    background: white !important;
    border-radius: 0.25rem !important;
  }
}

.gray-btn {
  color: #888888 !important;
}

.input-text-color {
  color: rgba(#fff, .7) !important;
}

.video-input  {
  &::placeholder {
    color: #C1272D !important;
  }
}

.pulsate-fwd {
  -webkit-animation: pulsate-fwd 0.5s ease-in-out both;
  animation: pulsate-fwd 0.5s ease-in-out both;
}

@-webkit-keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.restaurant-preview-swiper-pagination {
  .swiper-pagination-bullet {
    background: #ffffffad !important;
  }
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.restaurant-preview-body {
  overflow-x: hidden;

  .swiper {
    pointer-events: none;
  }

  .swiper-wrapper {
    pointer-events: none;
  }

  .swiper-slide {
    pointer-events: none;
  }
}

.dropdown-toggle {
  &.icon-v2 {
    &::after {
      display: none !important;
    }
  }
}

.side-arrow-right {
  left: 1rem;
}

.side-arrow-left {
  right: 1rem;
}

.left-n3rem {
  position: absolute;
  right: -3rem;
  top: 30vh;
}

.right-n3rem {
  position: absolute;
  right: -3rem;
  top: 44vh;
}

.right-n44 {
  right: -44px !important;
}

.right-n72 {
  right: -72px !important;
}

.arrow-left-n3rem {
  position: absolute;
  left: -4rem;
  top: 44vh;
}

.arrow-right-n3rem {
  position: absolute;
  right: -4rem;
  top: 44vh;
}

.arrow-bar {
  width: 3rem;
  height: 3rem;
  background-color: #ffffff15;
  backdrop-filter: blur(5px);

  &:hover {
    background-color: #ffffff30;
  }
}

.dropdown-header:hover {
  color: #a3a4a6 !important;
  cursor: default;
}

.dropdown-menu-m-large {
  z-index: 10000;
  height: 56vh;
  overflow-y: auto;
}

.left-11px {
  left: 11px !important;
}

.modal-backdrop {
  z-index: 1050 !important;
}

quill-editor {
  display: block !important;

  .ql-editor {
    overflow-x: hidden;

    &.ql-blank::before {
      color: #babbbc !important;
    }

    p, span {
      color: white !important;
    }
  }
}

ul.about-restaurant {
  li {
    margin-top: .5rem;
  }

  .icon-v2 {
    width: 28px !important;
  }
}

.border-bottom-white {
  border-bottom: 1px solid #ccc;
}

.object-fit-cover {
  object-fit: cover;
}

.custom-select-navbar-title {
  font-weight: 500;
  color: #181c21e6;
  font-size: 0.96rem !important;
}

.favorites-video-gradient {
  background: linear-gradient(180deg,
    rgba(0, 0, 0, .16) 0,
    rgba(0, 0, 0, .14) 4.5%,
    rgba(0, 0, 0, .12) 7%,
    rgba(0, 0, 0, .1) 10.35%,
    rgba(0, 0, 0, .08) 13.85%,
    rgba(0, 0, 0, .06) 17.35%,
    rgba(0, 0, 0, .04) 20.85%,
    rgba(0, 0, 0, .02) 24.35%,
    rgba(0, 0, 0, 0) 27.85%,
    rgba(0, 0, 0, 0) 31.35%,
    rgba(0, 0, 0, 0) 34.85%,
    rgba(0, 0, 0, 0) 38.35%,
    rgba(0, 0, 0, 0) 41.85%,
    rgba(0, 0, 0, 0) 45.35%,
    rgba(0, 0, 0, 0) 48.85%,
    rgba(0, 0, 0, 0) 52.35%,
    rgba(0, 0, 0, 0) 55.85%,
    rgba(0, 0, 0, 0) 59.35%,
    rgba(0, 0, 0, 0) 62.85%,
    rgba(0, 0, 0, 0) 66.35%,
    rgba(0, 0, 0, 0) 69.85%,
    rgba(0, 0, 0, 0) 73.35%,
    rgba(0, 0, 0, 0) 76.85%,
    rgba(0, 0, 0, 0) 80.35%,
    rgba(0, 0, 0, 0) 83.85%,
    rgba(0, 0, 0, 0) 87.35%,
    rgba(0, 0, 0, 0.0) 90.85%,
    rgba(0, 0, 0, 0) 94.35%,
    rgba(0, 0, 0, 0) 97.85%,
    rgba(0, 0, 0, 0.0)
  )
}

.input-placeholder {
  padding-left: 20px !important;
}

.ngb-typeahead-item.disabled {
  pointer-events: none;
  opacity: 0.5;
  background-color: transparent !important; /* Убираем фон при наведении */
  cursor: default;
}

.red-dot {
  width: 7px;
  height: 7px;
  background-color: #C1272D;
  border-radius: 50%;
  position: absolute;
}

.h-0 {
  height: 0 !important;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.form-control-input:hover::placeholder,
.form-control-input:focus::placeholder {
  color: #aaa; /* Make placeholder visible on hover or focus */
}

.input-placeholder-hidden::placeholder {
  color: transparent; /* Hide placeholder by default */
  transition: color 0.2s ease;
}

.text-red {
  color: red;
}
