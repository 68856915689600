ngb-modal-backdrop.fade {
  transition: none !important;
}

.modal-backdrop-light {
  background: rgba(0, 0, 0, 0.2) !important;
}

ngb-modal-window {
  cursor: pointer;

  .modal-content {
    cursor: default;
  }

  &.modal .modal-dialog {
    animation: ngDefaultModalAnimation .3s ease-out;

    @media (min-width: 576px) {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }

  &.modal-top .modal-dialog {
    animation: ngTopModalAnimation .3s ease-out;
  }

  &.modal-top-search {
    animation: ngTopModalAnimation .3s ease-out;

    .modal-content {
      background-color: #fff;
      backdrop-filter: blur(5px);
    }
  }

  &.modal-bottom {
    bottom: 0;
    height: auto !important;
    color: #ffffff;

    .modal-dialog {
      position: relative;
      animation: ngBottomModalAnimation .5s ease-out;
    }

    &.modal-blur {
      .modal-content {
        backdrop-filter: blur(10px);
        background: #000000bd !important;
      }
    }
  }

  &.modal-slide .modal-dialog {
    animation: ngSlideModalAnimation .3s ease-out;
  }

  &.modal-fill-in .modal-dialog {
    animation: ngFillInModalAnimation .3s ease-out;
  }

  [dir=rtl] &.modal-slide .modal-dialog,
  &.modal-slide.left .modal-dialog {
    animation: ngRtlSlideModalAnimation .3s ease-out;
  }

  &.modal-slide-in-bck-center .modal-dialog {
    -webkit-animation: slide-in-bck-center 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-bck-center 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  .modal-header {
    .modal-header-title {
      font-size: 1rem;
    }
  }

  .modal-body.p-0 {
    .modal-body-box {
      padding: 1.5625rem
    }
  }

  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 576px;
      //margin-top: 0;
    }
  }

  &.modal-top-rounded {
    .modal-dialog {
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;

      .modal-content {
        height: 100%;
        -webkit-box-shadow: none;
        box-shadow: none;
      }

      .modal-content,
      .modal-top-standard .modal-top-standard-footer,
      .modal-top-standard .modal-top-standard-footer .modal-top-standard-footer-content {
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
      }
    }
  }

  &.modal-bottom-rounded {
    overflow-y: hidden !important;
    transition: none !important;

    .modal-dialog {
      .modal-content {
        height: 100%;
        -webkit-box-shadow: none;
        box-shadow: none;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
      }
    }
  }

  &.modal-overflow-y-hidden {
    overflow-y: hidden !important;

    .modal-body {
      overflow-y: hidden !important;
    }
  }

  &.modal-subscribe-desktop {
    backdrop-filter: grayscale(1) blur(7px);
    background: #00000060;

    &.explore {
      background: none !important;
    }

    .modal-dialog {
      height: 100%;
      min-width: 1170px;
      margin-left: 0 !important;
      right: auto !important;
      left: 0 !important;
      backdrop-filter: none;
      background-color: transparent;

      .modal-content {
        display: block;
        height: 100%;
        width: calc(100vw - ((100vw - 45rem) / 2));
      }
    }
  }

  &.modal-login {
    .modal-dialog {
      height: 100%;

      .modal-content {
        display: block;
        height: 100%;
      }
    }
  }

  &.modal-login-desktop-expanded {
    backdrop-filter: grayscale(1) blur(7px);
    background: #00000060;

    .modal-dialog {
      height: 100%;
      width: calc(100vw - ((100vw - 40rem) / 2));
      margin-left: 0 !important;

      .modal-content {
        display: block;
        height: 100%;
        backdrop-filter: blur(0) !important;
      }
    }
  }

  &.modal-login-desktop {
    .modal-dialog {
      .modal-content {
        backdrop-filter: none !important;
        background: rgba(0, 0, 0, 20%) !important;
      }
    }
  }

  &.modal-restaurant-desktop {
    backdrop-filter: blur(7px) grayscale(1);
    background: rgba(0, 0, 0, 0.8);

    .modal-dialog {
      right: auto !important;
      left: 0 !important;
      animation: ngRtlSlideModalAnimation 10ms ease-out;
      height: 100%;
      margin-left: 0 !important;
      width: 100%;
      min-width: 1140px;
      background: transparent;
      //overflow: hidden !important;

      .modal-content {
        display: block;
        height: 100%;
        background: transparent;
        box-shadow: none !important;
      }
    }
  }

  &.modal-password-desktop {
    backdrop-filter: grayscale(1);
    background: #00000060;

    .modal-dialog {
      .modal-content {
        display: block;
        height: 30%;
        margin-top: 2rem;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &.modal-h-100 {
    .modal-dialog {
      margin: 0 auto;

      .modal-content {
        min-height: 100vh;
      }
    }
  }

  &.modal-gradient {
    color: #ffffff;

    a {
      color: #ffffff;

      &.dropdown-item {
        color: #4E5155;
      }
    }

    .modal-content {
      background-image: linear-gradient(180deg, black, white);
    }
  }

  &.modal-gradient-black-gray {
    color: #ffffff;

    a {
      color: #ffffff;

      &.dropdown-item {
        color: #4E5155;
      }
    }

    .modal-content {
      background-color: #FFFFFF35;
      backdrop-filter: blur(10px);
    }
  }

  &.modal-transparent {
    color: #ffffff;

    a:not(.dropdown-item) {
      color: #ffffff;
    }

    .modal-content {
      backdrop-filter: none !important;
      background: rgba(0, 0, 0, 20%) !important;
    }

    &.bright {
      .modal-content {
        background: rgba(0, 0, 0, 0.5) !important;
      }
    }
  }

  &.modal-black {
    color: #ffffff;

    a {
      color: #ffffff;
    }

    .modal-content {
      background: black !important;
    }
  }

  &.modal-blur-dark {
    color: #ffffff;

    a {
      color: #ffffff;
    }

    .modal-dialog {
      background: #ffffff10 !important;
      backdrop-filter: blur(10px);
    }

    .modal-content {
      padding: 0;
      background: none;
    }
  }

  &.modal-blur-grey {
    color: #ffffff;

    a {
      color: #ffffff;
    }

    .modal-dialog {
      background: #FFFFFF30 !important;
      backdrop-filter: blur(10px);
    }

    .modal-content {
      padding: 0;
      background: none;
    }
  }

  &.modal-blur-light-grey {
    color: #ffffff;

    a {
      color: #ffffff;
    }

    .modal-dialog {
      background: #00000030 !important;
      backdrop-filter: blur(10px);
    }

    .modal-content {
      padding: 0;
      background: none;
    }
  }

  &.modal-darker {
    .modal-dialog {
      background: #00000060 !important;
    }
  }

  &.modal-light-mobile {
    color: #ffffff;

    a {
      color: #ffffff;
    }

    .modal-content {
      padding: 0;
      background: rgba(0, 0, 0, 0%) !important;
    }
  }

  &.login-helper {
    color: #ffffff;

    a {
      color: #ffffff;
    }

    .modal-content {
      padding: 0;
      background: rgba(0, 0, 0, 0.2) !important;
    }
  }

  &.modal-blur-light {
    color: #ffffff;

    a {
      color: #ffffff;
    }

    .modal-content {
      padding: 0;
      background: rgba(0, 0, 0, 0%) !important;
      backdrop-filter: blur(5px);
    }
  }

  &.modal-shadow-none {
    .modal-content {
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }

  &.modal-full-width {
    .modal-dialog {
      max-width: 100%;

      @media (max-width: 576px) {
        margin: 0;
      }
    }
  }

  &.modal-restaurant-edit {
    .modal-content {
      margin-top: 2rem !important;
    }
  }

  &.modal-about-restaurant {
    .modal-dialog {
      max-width: 100%;
    }

    .modal-dialog {
      max-width: 80rem !important;
    }

    .modal-content {
      margin-top: 10rem !important;
      margin-bottom: 5rem !important;
    }
  }

  &.modal-full-width-desktop {
    .modal-dialog {
      max-width: 100%;
    }
  }

  &.modal-center {
    display: flex !important;
    align-items: center !important;
    flex-grow: 1;

    .modal-dialog {
      margin: 0.5rem auto;
      width: 260px !important;

      .modal-content {
        padding: 1.25rem;
        border-radius: 12px;
      }
    }
  }

  &.modal-center-narrowed {
    display: flex !important;
    align-items: center !important;
    flex-grow: 1;

    .modal-dialog {
      width: 75%;
      margin: 0.5rem auto;

      .modal-content {
        border-radius: 8px;
      }
    }

    .modal-body {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }

  &.modal-center-square {
    display: flex !important;
    align-items: inherit !important;
    padding-top: 11rem !important;

    .modal-dialog {
      width: 360px !important;
      margin: 0.5rem auto;
      animation: center-modal-animation 0.1s ease-out;

      .modal-content {
        border-radius: 12px;
      }
    }

    .modal-body {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  &.modal-favorite-desktop {
    background: #00000060;
    display: flex !important;
    align-items: inherit !important;
    padding: 0 4rem !important;

    .modal-dialog {
      width: 100%;
      max-width: none !important;
      margin: 0.5rem auto;
      animation: center-modal-animation 0.1s ease-out;
      display: flex;
      justify-content: center;

      .modal-content {
        width: 70rem;
        margin-top: 2rem;
      }
    }

    .modal-body {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  &.modal-post-desktop {
    backdrop-filter: blur(7px) grayscale(1);
    background: #000000cc;
    display: flex !important;
    align-items: inherit !important;

    .modal {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
    }

    .modal-dialog {
      max-width: none !important;
      animation: center-modal-animation 0.1s ease-out;
      display: flex;
      justify-content: center;
      margin-top: 20px;

      .modal-content {
        width: 100%;
        max-height: calc(100vh - 40px);
        margin-top: auto;
        margin-bottom: auto;
        background-color: rgba(24, 28, 33) !important;
      }
    }

    .modal-body {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  &.restaurant-desktop {
    display: flex !important;
    align-items: flex-end !important;
    justify-content: center;

    .modal {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
    }

    .modal-dialog {
      max-width: none !important;
      animation: center-modal-animation 0.1s ease-out;
      display: flex;
      justify-content: center;
      margin-top: 20px;

      .modal-content {
        width: 1188px !important;
        height: calc(100vh - 20px);
        margin-top: auto;
        margin-bottom: auto;
        background-color: rgba(24, 28, 33) !important;
      }
    }

    .modal-body {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  &.modal-search-square {
    display: flex !important;
    align-items: inherit !important;
    padding-top: 11rem !important;

    .modal-dialog {
      width: 600px !important;
      height: 360px !important;
      margin: 0.5rem auto;
      animation: center-modal-animation 0.1s ease-out;
      backdrop-filter: blur(10px);
      background: #FFFFFF50;
      border-radius: 12px;

      .modal-content {
        background: transparent !important;
        box-shadow: none !important;
        font-size: 1.05rem !important;
      }
    }

    .modal-body {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  &.modal-centered {
    display: flex !important;
    align-items: center !important;
    flex-grow: 1;
    margin-top: 2rem !important;
    padding-top: 3rem !important;

    .modal-dialog {
      width: 300px !important;
      padding-top: 3rem !important;

      .modal-content {
        margin-top: 3rem !important;
        padding: 2rem !important;
      }
    }
  }

  &.modal-border-radius-0-125 {
    .modal-dialog {
      .modal-content {
        border-radius: 0.125rem;
      }
    }
  }

  &.modal-bottom-main-menu-nav {
    top: auto;

    .modal-dialog {
      margin: 1.75rem auto 0;

      .modal-content {
        background-color: transparent;
      }
    }
  }

  &.modal-top-auto {
    top: auto;
  }

  &.modal-width-80 {
    .modal-dialog {
      width: 80%;

      @media (min-width: 576px) {
        width: 460.8px;
      }
    }
  }

  &.modal-width-35 {
    .modal-dialog {
      width: 35%;

      @media (min-width: 192px) {
        width: 153.2px;
      }
    }
  }

  &.modal-additions {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
    overflow: auto;

    .modal-dialog {
      max-width: 80rem !important;
    }
  }

  &.modal-no-animation {
    transition: none !important;

    .modal-dialog {
      animation: none !important;
      transition: none !important;
    }
  }

  &.modal-hero {
    color: #ffffff;

    a {
      color: #ffffff;
    }

    .modal-dialog {
      backdrop-filter: blur(7px);
    }

    .modal-content {
      padding: 0;
      background: none;
    }
  }

  &.modal-search {
    color: #ffffff;

    a {
      color: #ffffff;
    }

    .modal-dialog {
      backdrop-filter: blur(7px);
    }

    .modal-content {
      padding: 0;
      background: none;
    }
  }
}

.modal-preview {
  .modal-dialog {
    margin: 0;
    margin-left: auto !important;
    margin-right: auto !important;

    .modal-content {
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      box-shadow: none !important;
    }
  }
}

.modal-swiper {
  .modal-dialog {
    margin-left: auto !important;
    margin-right: auto !important;

    .modal-content {
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      background-color: transparent;

      .swiper-slide {
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
      }
    }
  }
}

.modal-swiper-fav {
  .modal-dialog {
    margin-left: auto !important;
    margin-right: auto !important;

    .modal-content {
      background-color: transparent;

      .swiper-slide {
        background-color: white;
      }
    }
  }
}

.modal-slide {
  &.left {
    .modal-dialog {
      right: auto !important;
      left: 0 !important;
    }
  }

  &.search-filters-modal {
    .modal-dialog {
      max-width: 60%;
      background-color: #ffffff35;
      backdrop-filter: blur(10px);

      .modal-content {
        background: none !important;
        box-shadow: none !important;
        padding: 0;
      }
    }
  }
}

ngb-modal-backdrop {
  background-color: rgba(0, 0, 0, .5) !important;
  opacity: 1 !important;

  &.modal-search-desktop-backdrop {
    background-color: rgba(0, 0, 0, .55) !important;
  }

  &.modal-restaurant-desktop-backdrop {
    background-color: rgba(0, 0, 0, 0.35) !important;
    backdrop-filter: blur(5px);
  }

  &.modal-favorite {
    background-color: transparent;
    opacity: 0 !important;
  }

}
